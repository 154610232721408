import React from "react";
import { Link } from "react-router-dom";

const AnnouncementsPage = () => (
  <>
    <h2 style={{ marginBottom: "0.5em", marginTop: "0.5em" }}>Announcements</h2>
    <table className="table table-bordered">
      <thead className="thead-dark">
        <tr>
          <th scope="col">Free Church of Christ Chatsworth</th>
          <th scope="col">Free Church of Christ Phoenix</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>
            <p>Sunday Morning Worship Service - 9:00am</p>
            <p>Sunday School - Straight after morning Service</p>
            <p>Tuesday Evening Home Cell - 7:00pm</p>
            <p>Youth Meeting - Every Friday 7:00pm</p>
          </td>
          <td>
            <p>Sunday Morning Worship Service - 8:30am</p>
            <p>Sunday School - Straight after morning Service</p>
            <p>Wednesday Evening Home Cell - 7:00pm</p>
            <p>Friday Intercessory Prayer - 7:00pm</p>
          </td>
        </tr>

        <tr className="table-light">
          <th colSpan="2" align="center">
            Please see the <Link to="/church-schedule"> Church Schedule </Link>
            for further details about all Services.
          </th>
        </tr>

        <tr id="banking-details" className="table-secondary">
          <th colSpan="2" align="center">
            Banking Details
          </th>
        </tr>
        <tr>
          <td>
            Bank: Standard Bank
            <br />
            Branch: Chatsworth (Branch code 044126)
            <br />
            Account Name: Free Church of Christ
            <br />
            Account Number: 053 012 941
            <br />
            Account Type: Current (Cheque)
            <br />
            Reference: Name &amp; Fund
            <br />
            E.G. Tithes, Mission, Building Fund, Pastors Appreciation
          </td>
          <td>
            Bank: Standard Bank
            <br />
            Branch: Chatsworth (Branch code 044126)
            <br />
            Account Name: Free Church of Christ
            <br />
            Account Number: 250 616 211
            <br />
            Account Type: Current (Cheque)
            <br />
            Reference: Name &amp; Fund
            <br />
            E.G. Tithes, Mission, Building Fund, Pastors Appreciation
          </td>
        </tr>

        <tr className="table-secondary">
          <th colSpan="2" align="center">
            Special Days, (Birthdays, Anniversaries, Exciting News)
          </th>
        </tr>
        <tr>
          <td colSpan="2">
            <p align="center">&nbsp;</p>
          </td>
        </tr>
        <tr>
          <td width="50%">
            <p align="center">&nbsp;</p>
          </td>
          <td width="50%">
            <p align="center">&nbsp;</p>
          </td>
        </tr>
        <tr className="table-secondary">
          <th colSpan="2" align="center">
            Other Announcements
          </th>
        </tr>
        <tr>
          <td width="50%">
            <p align="center">&nbsp;</p>
          </td>
          <td width="50%">
            <p align="center">&nbsp;</p>
          </td>
        </tr>
      </tbody>
    </table>
  </>
);

export default AnnouncementsPage;
