import React from "react";
import { Link } from "react-router-dom";
import ImgFigure from "../components/ImgFigure";
import ImageModal from "../components/ImageModal";

const BibleCollegePage = () => (
  <>
    <h2>Bible College</h2>
    <p style={{ marginBottom: "1em" }}>
      For more information about the college, please{" "}
      <Link to="/contact-us">contact Pastor Dean</Link>.
    </p>
    <ImageModal modalId="collegeModal" />

    <div>
      <table
        className="table table-bordered college-table"
        style={{ tableLayout: "fixed" }}
      >
        <tbody>
          <tr>
            <td>
              <ImgFigure
                modalId="collegeModal"
                imgsrc="img/college/college-grad-99-dcr.jpg"
                caption="Millennium Graduation."
              />
            </td>
            <td>
              <ImgFigure
                modalId="collegeModal"
                imgsrc="img/college/DR-Ashley-Govender.jpg"
                caption="Guest of Honour: Dr Ashley Govender."
              />
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <div className="college">
      <table className="table table-bordered college-table">
        <tbody>
          <tr>
            <td>
              <ImgFigure
                modalId="collegeModal"
                imgsrc="img/college/first-Graduates1.jpg"
                caption="First Graduates."
              />
            </td>
            <td>
              <ImgFigure
                modalId="collegeModal"
                imgsrc="img/college/college-grad-99.jpg"
                caption="Millennium Graduation."
              />
            </td>
            <td>
              <ImgFigure
                modalId="collegeModal"
                imgsrc="img/college/past-and-present-graduates.jpg"
                caption="Past &amp; Present Graduates."
              />
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <div className="college">
      <table className="table table-bordered college-table">
        <tbody>
          <tr>
            <td>
              <ImgFigure
                modalId="collegeModal"
                imgsrc="img/college/graduates-awaiting.jpg"
                caption="Past &amp; Present Graduates Awaiting."
              />
            </td>
            <td>
              <ImgFigure
                modalId="collegeModal"
                imgsrc="img/college/father-and-daughter.jpg"
                caption="Father and Daughter."
              />
            </td>
            <td>
              <ImgFigure
                modalId="collegeModal"
                imgsrc="img/college/husband-and-wife2.jpg"
                caption="Husband and Wife."
              />
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <div className="college">
      <table className="table table-bordered college-table">
        <tbody>
          <tr>
            <td>
              <ImgFigure
                modalId="collegeModal"
                imgsrc="img/college/husband-and-wife.jpg"
                caption="Husband and Wife."
              />
            </td>
            <td>
              <ImgFigure
                modalId="collegeModal"
                imgsrc="img/college/mother-and-daughter.jpg"
                caption="Mother and Daughter."
              />
            </td>
            <td>
              <ImgFigure
                modalId="collegeModal"
                imgsrc="img/college/Nelson1.jpg"
                caption="Nelson."
              />
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <div className="college">
      <table className="table table-bordered college-table">
        <tbody>
          <tr>
            <td>
              <ImgFigure
                modalId="collegeModal"
                imgsrc="img/college/pastor-alfie-murugesa.jpg"
                caption="Pastor Alfie Murugasa."
              />
            </td>
            <td>
              <ImgFigure
                modalId="collegeModal"
                imgsrc="img/college/vivian-sathiaseelan.jpg"
                caption="Vivian Sathiaseelan."
              />
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </>
);

export default BibleCollegePage;
