import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import HomePage from "./pages/HomePage";
import AboutPage from "./pages/AboutPage";
import LeadershipPage from "./pages/LeadershipPage";
import SchedulePage from "./pages/SchedulePage";
import AnnouncementsPage from "./pages/AnnouncementsPage";
import BibleCollegePage from "./pages/BibleCollegePage";
import PhotoGalleryPage from "./pages/PhotoGalleryPage";
import MissionWorkPage from "./pages/MissionWorkPage";
import BibleSearchPage from "./pages/BibleSearchPage";
import ContactUsPage from "./pages/ContactUsPage";
import LinksPage from "./pages/LinksPage";
import NotFoundPage from "./pages/NotFoundPage";
import NavBar from "./NavBar";
import Footer from "./Footer";
import ScrollTop from "./components/ScrollTop";

function App() {
  return (
    <Router>
      <ScrollTop />
      <div className="App d-flex flex-column">
        <NavBar />
        <div id="page-body">
          <Switch>
            <Route path="/" component={HomePage} exact />
            <Route path="/about" component={AboutPage} />
            <Route path="/leadership" component={LeadershipPage} />
            <Route path="/church-schedule" component={SchedulePage} />
            <Route path="/announcements" component={AnnouncementsPage} />
            <Route path="/college" component={BibleCollegePage} />
            <Route path="/photo-gallery" component={PhotoGalleryPage} />
            <Route path="/mission-work" component={MissionWorkPage} />
            <Route path="/Bible" component={BibleSearchPage} />
            <Route path="/contact-us" component={ContactUsPage} />
            <Route path="/links" component={LinksPage} />
            <Route component={NotFoundPage} />
          </Switch>
        </div>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
