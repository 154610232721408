import React from "react";
import { Link } from "react-router-dom";
import SliderItem from "../components/SliderItem";

const MissionWorkPage = () => {
  // SET NUMBER OF PICTURES IN CAROUSEL
  const numPictures = 13;
  let carouselIndicators = [];
  for (let i = 0; i < numPictures; i++) {
    carouselIndicators.push(
      <li
        className={i === 0 ? "active" : ""}
        key={String(i)}
        data-target="#carouselExampleCaptions"
        data-slide-to={String(i)}
      ></li>
    );
  }

  return (
    <>
      <h2>Mission Work</h2>

      <div className="bd-example" id="photo-slider">
        <div
          id="carouselExampleCaptions"
          className="carousel slide"
          data-ride="carousel"
        >
          <ol className="carousel-indicators">{carouselIndicators}</ol>
          <div className="carousel-inner">
            <SliderItem
              first
              imgsrc="img/missions/At-rkkhans.jpg"
              heading="Feeding at RK Khan Hospital."
            />

            <SliderItem
              imgsrc="img/missions/Blanket-distribution.jpg"
              heading="Blanket Distribution."
            />

            <SliderItem
              imgsrc="img/missions/Distribution-at-Aids-Clinic-RKK.jpg"
              heading="Distribution at AIDS Clinic, RK Khan."
            />

            <SliderItem
              imgsrc="img/missions/Feeding-Mission.jpg"
              heading="Feeding Mission."
            />

            <SliderItem
              imgsrc="img/missions/IMG_8413.jpg"
              heading="Preparing for Feeding Mission."
            />

            <SliderItem
              imgsrc="img/missions/IMG_8446.jpg"
              heading="Feeding Mission at RK Khan."
            />

            <SliderItem
              imgsrc="img/missions/IMG_8476.jpg"
              heading="Feeding Mission at RK Khan."
            />

            <SliderItem
              imgsrc="img/missions/IMG_8504.jpg"
              heading="Feeding Mission at RK Khan."
            />

            <SliderItem
              imgsrc="img/missions/IMG_8577.jpg"
              heading="Feeding Mission at RK Khan."
            />

            <SliderItem
              imgsrc="img/missions/IMG-20140718-00525.jpg"
              heading="Blanket Drive."
            />

            <SliderItem
              imgsrc="img/missions/IMG-20140718-00531.jpg"
              heading="Blanket Drive."
            />

            <SliderItem
              imgsrc="img/missions/IMG-20140718-00532.jpg"
              heading="Blanket Drive."
            />
          </div>
          <a
            className="carousel-control-prev"
            href="#carouselExampleCaptions"
            role="button"
            data-slide="prev"
          >
            <span
              className="carousel-control-prev-icon"
              aria-hidden="true"
            ></span>
            <span className="sr-only">Previous</span>
          </a>
          <a
            className="carousel-control-next"
            href="#carouselExampleCaptions"
            role="button"
            data-slide="next"
          >
            <span
              className="carousel-control-next-icon"
              aria-hidden="true"
            ></span>
            <span className="sr-only">Next</span>
          </a>
        </div>
      </div>
      <h5 style={{ marginBottom: "1em" }}>
        Remember that the 1st Sunday of every month is Mission Sunday!
      </h5>
      <table className="table table-bordered">
        <tbody>
          <tr>
            <td>
              <p>Matthew 28:19-20 (NLT)</p>
              <strong>19</strong> Therefore, go and make disciples of all the
              nations, baptizing them in the name of the Father and the Son and
              the Holy Spirit. <strong>20</strong> "Teach these new disciples to
              obey all the commands I have given you. And be sure of this: I am
              with you always, even to the end of the age."
            </td>
            <td>
              <p>Romans 10:13-14 (NLT)</p>
              <strong>13</strong> For "Everyone who calls on the name of the
              Lord will be saved." <strong>14</strong> But how can they call on
              him to save them unless they believe in him? And how can they
              believe in him if they have never heard about him? And how can
              they hear about him unless someone tells them?
            </td>
          </tr>
          <tr>
            <td>
              <p>Proverbs 14:31 (NLT)</p>
              <strong>31</strong> Those who oppress the poor insult their Maker,
              but helping the poor honors him.
            </td>
            <td>
              <p>Proverbs 28:27 (NLT)</p>
              <strong>27</strong> Whoever gives to the poor will lack nothing,
              but those who close their eyes to poverty will be cursed.
            </td>
          </tr>
          <tr>
            <td>
              <p>Proverbs 22:9 (NLT)</p>
              <strong>9</strong> Blessed are those who are generous, because
              they feed the poor.
            </td>
            <td>
              <p>
                <strong>A Wife of Noble Character</strong> - Proverbs 31:20
                (NLT)
              </p>
              <strong>20</strong> She extends a helping hand to the poor and
              opens her arms to the needy.
            </td>
          </tr>

          <tr>
            <td colSpan="2" align="left">
              The Free Church of Christ supports the following International
              Missions:
              <ul>
                <li>
                  <strong>Open Doors</strong> - The world's largest outreach to
                  persecuted Christians in the most high-risk places.
                </li>
                <li>
                  <strong>WORLD EVANGELISM MINISTRIES</strong> - "Every Village
                  a Fellowship, Every Village a Worship House."
                </li>
              </ul>
              If you would like to donate to our Mission fund, NB. Please
              include "Mission Fund" in your deposit reference. Banking Details
              can be found <Link to="/announcements">here</Link>.
            </td>
          </tr>

          <tr className="table-secondary">
            <th colSpan="2" align="center">
              The Free Church of Christ is committed to missionary work both
              locally and internationally.
            </th>
          </tr>
        </tbody>
      </table>
    </>
  );
};

export default MissionWorkPage;
