import React from "react";

const LinksPage = () => (
  <>
    <h2>
      <a href="https://www.opendoors.org/">Open Doors</a>
    </h2>
    <p>
      The world's largest outreach to persecuted Christians in the most
      high-risk places.
    </p>

    <h2>
      <a href="https://www.biblegateway.com/">Bible Gateway</a>
    </h2>
    <p>Online Bible, search verses, topics. Audio Bible, reading plans etc.</p>

    <h2>
      <a href="http://www.sermoncentral.com/">Sermon Central</a>
    </h2>
    <p>
      Sermons, outlines, preaching ideas for sermon preparation. Church videos,
      sermon video illustrations &amp; worship music videos.
    </p>
  </>
);

export default LinksPage;
