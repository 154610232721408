import React from "react";
import { Link } from "react-router-dom";
import logo from "./logo.svg";
import NavItem from "./components/NavItem";
import DropDownItem from "./components/DropDownItem";

const styles = {
  header: {
    backgroundColor: "#b30b0b",
    alignItems: "center",
    justifyContent: "center"
  }
};

const NavBar = () => (
  <header className="sticky-top" style={styles.header}>
    <nav
      style={{ flexDirection: "column" }}
      className="navbar navbar-expand-md navbar-dark"
    >
      <div id="title-and-toggler">
        <span id="logo-and-heading" className="navbar-brand">
          <img id="logo" src={logo} alt="Free Church of Christ" />
          <Link to="/" id="title">
            Free Church of Christ
          </Link>
        </span>

        <button
          id="menu-toggler"
          className="navbar-toggler"
          data-toggle="collapse"
          data-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
      </div>

      <i className="navbar-brand" id="motto">
        Where <u>Everyone</u> is Someone, and <strong>Jesus Christ</strong> is{" "}
        <strong>Lord</strong>.
      </i>

      <div className="collapse navbar-collapse" id="navbarSupportedContent">
        <div className="navbar-nav mr-auto mt-2 mt-lg-0">
          <NavItem to="/" title="Home" />

          <div className="nav-item dropdown">
            <span
              className="nav-link dropdown-toggle"
              id="navbarDrop-down"
              role="button"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              About Us
            </span>

            <div
              id="menu"
              className="dropdown-menu"
              aria-labelledby="navbarDrop-down"
            >
              <DropDownItem to="/about" title="Who We Are" />
              <DropDownItem to="/leadership" title="Leadership" />
              <DropDownItem to="/photo-gallery" title="Photo Gallery" />
            </div>
          </div>

          <NavItem to="/church-schedule" title="Schedule" />
          <NavItem to="/announcements" title="Announcements" />
          <NavItem to="/college" title="Bible College" />
          <NavItem to="/mission-work" title="Mission Work" />
          <NavItem to="/Bible" title="Bible Search" />
          <NavItem to="/links" title="Links" />
          <NavItem to="/contact-us" title="Contact Us" />
        </div>
      </div>
    </nav>
  </header>
);

export default NavBar;
