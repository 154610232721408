import React from "react";
import moment from "moment";

const SchedulePage = () => {
  let now = moment().format("Do MMMM YYYY");

  return (
    <>
      <h3>Church Schedule - {now}</h3>
      <p>
        (<a href="assets/church_schedule.pdf">Click Here</a> to Download the
        Schedule as a PDF.)
      </p>
      <img
        src="img/schedule.jpg"
        alt="Church Schedule"
        style={{ width: "95vw" }}
      />
    </>
  );
};

export default SchedulePage;
