import React from "react";

const NotFoundPage = () => (
  <>
    <h1 style={{ marginTop: "1em", marginBottom: "1em" }}>
      We seeked, but we could not find.
    </h1>
    <h5>Page Not Found. Please try one of the above links.</h5>
    <img className="images" id="not-found" src="img/notfound.png" alt="" />
  </>
);

export default NotFoundPage;
