import React from "react";

const Footer = () => (
  <>
    <footer
      id="sticky-footer"
      className="footer bg-dark text-white"
      style={{ paddingTop: "0.5em", paddingBottom: "0.5em" }}
    >
      <div className="container text-center">
        <small>
          If you have any feedback on how we can make our new website better
          please do contact us. We would like to hear from you.
        </small>{" "}
        <small>
          Contact Site administrator: Brandon Naguran at{" "}
          <a href="mailto:audi500@ymail.com">audi500@ymail.com</a>.
        </small>
      </div>
    </footer>
  </>
);

export default Footer;
