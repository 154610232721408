import React from "react";

const ContactUsPage = () => (
  <>
    <h2 style={{ margin: "0.5em" }}>Contact Us!</h2>
    <div style={{ display: "inline-flex", marginBottom: "2em" }}>
      <div className="contacts">
        <h5>Rev. Dean C. Reddy</h5>
        <hr />
        <p>Cell: 084 562 6970</p>
        <p>Tel: 031 462 6970</p>
        <p>
          Email:{" "}
          <a href="mailto:dcreddy@telkomsa.co.za">dcreddy@telkomsa.co.za</a>
        </p>
        <p>(Senior Pastor)</p>
      </div>

      <div className="contacts">
        <h5>Or You Can Write To:</h5>
        <hr />
        <p>THE FREE CHURCH OF CHRIST</p>
        <p>13 Quail Rd,</p>
        <p>Yellowwood Park,</p>
        <p>Durban,</p>
        <p>4004</p>
      </div>
    </div>

    <table width="100%" border="1">
      <tbody>
        <tr id="mapTitles">
          <td width="50%">Free Church of Christ: Phoenix</td>
          <td width="50%">Free Church of Christ: Chatsworth</td>
        </tr>

        <tr id="maps">
          <td width="50%" align="center">
            <iframe
              title="Free Church of Christ: Phoenix"
              scrolling="no"
              marginHeight="0"
              marginWidth="0"
              src="https://maps.google.com/maps?hl=en&amp;q=eastbury secondary school, south africa&amp;ie=UTF8&amp;t=roadmap&amp;z=15&amp;iwloc=B&amp;output=embed"
              width="100%"
              height="400em"
              frameBorder="0"
            ></iframe>
          </td>

          <td width="50%" align="center">
            <iframe
              title="Free Church of Christ: Chatsworth"
              scrolling="no"
              marginHeight="0"
              marginWidth="0"
              src="https://maps.google.com/maps?hl=en&amp;q=36 Dunveria Crescent, Croftdene Chatsworth&amp;ie=UTF8&amp;t=roadmap&amp;z=15&amp;iwloc=B&amp;output=embed"
              width="100%"
              height="400em"
              frameBorder="0"
            ></iframe>
          </td>
        </tr>
      </tbody>
    </table>
  </>
);

export default ContactUsPage;
