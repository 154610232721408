import React from "react";

const PhotoGalleryPage = () => (
  <>
    <h1>Photo Gallery</h1>
    <h4>(Coming Soon!)</h4>
  </>
);

export default PhotoGalleryPage;
