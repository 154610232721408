import React from "react";

const HomePage = () => (
  <>
    <h2>Welcome to the Free Church of Christ!</h2>
    <img id="logo" src="img/logo.gif" alt="Free Church of Christ" />
    <h5 style={{ marginBottom: "1em" }}>
      Come Join us in fellowship at our branches in Phoenix and Chatsworth,
      Durban, Kwa-Zulu Natal.
    </h5>
    <img className="images" src="img/awaken.jpg" alt="Isiah 40:31" />
  </>
);

export default HomePage;
