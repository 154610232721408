import React from "react";

const BibleSearchPage = props => {
  const query = props.location.search;

  const searchMessage = query.slice(13) ? (
    <h5>
      Searching for "
      <strong>
        {query
          .slice(13)
          .split("+")
          .join(" ")}
      </strong>
      ":
    </h5>
  ) : (
    <p>You Haven't Searched Anything - Please Search in the Bar Above!</p>
  );

  return (
    <>
      <h1>Bible Search</h1>

      <form
        className="input-group mb-3 search-custom"
        action="https://www.biblegateway.com/quicksearch/"
      >
        <input
          type="text"
          name="quicksearch"
          className="form-control mr-sm-2"
          placeholder="Lookup a Bible Passage ..."
          aria-label="Search"
          aria-describedby="searchButton"
        />
        <div className="input-group-append">
          <button
            className="btn btn-outline-success"
            type="submit"
            id="searchButton"
          >
            Search
          </button>
        </div>
      </form>

      {searchMessage}
      <div style={{ width: "50%", margin: "auto" }}>
        <p style={{ margin: "auto" }}>
          Search Provided by{" "}
          <span>
            <a
              href="https://www.biblegateway.com/"
              title="The Bible in multiple languages, versions, and formats"
            >
              <img
                src="https://www.biblegateway.com/assets/images/logos/bglogo_sm.gif?103106"
                alt="BibleGateway.com"
              />
            </a>
          </span>
        </p>

        <small>
          <a href="https://www.biblegateway.com/usage/form/">
            Include Bible Search
          </a>{" "}
          on your page.
          <br />
        </small>
        <img
          style={{ marginBottom: "1em" }}
          className="images"
          src="img/BibleLightRay.jpg"
          alt="Search the Word."
        />
      </div>
    </>
  );
};

export default BibleSearchPage;
